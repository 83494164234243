<template src="./ProfileUsers.html"></template>

<script>
import { mapGetters, mapActions } from "vuex";
import { SwiperSlide } from "vue-awesome-swiper";
import userService from "@/services/User";
import profileService from "@/services/Profile";
import followService from "@/services/Follow";
import certificateService from "@/services/Certificate";
import recommendationsService from "@/services/Recommendations";
import ProfileInformationCard from "./ProfileInformationCard/ProfileInformationCard";
import swiperLargeOption from "@/utils/swiper-large-option";
import ProfileRecommendation from "@/components/ProfileRecommendation/ProfileRecommendation";
import Certificates from "@/components/Certificates/Certificates";
import Avatar from "@/components/Avatar/Avatar";
import ArrobaMedellinArrobitaNoDataProfile from "@/components/ArrobaMedellinArrobitaNoDataProfile/ArrobaMedellinArrobitaNoDataProfile";
import ArrobaMedellinNoData from "../../components/ArrobaMedellinNoData/ArrobaMedellinNoData";
import { roleName } from "@/utils/constants";
import coursesService from "@/services/Courses";

export default {
  name: "profileUsers",
  components: {
    SwiperSlide,
    ProfileInformationCard,
    ProfileRecommendation,
    Certificates,
    Avatar,
    ArrobaMedellinArrobitaNoDataProfile,
    ArrobaMedellinNoData,
  },
  data() {
    return {
      FollowRole: "Student",
      userRole: null,
      typeCard: { button: "button--blue", progress: "is-info" },
      swiperSmallOption: swiperLargeOption, // swiperSmallOption,
      itemsToShow: Number(1),
      take: 20,
      skip: 0,
      urlCurriculum: null,
      profileUserFollow: null,
      userData: null,
      certificatesData: [],
      paramsId: null,
      roleName,
      recommendation: [],
      recommendationCounter: null,
      cvModal: false,
      ranking: null,
      relationCourse: false,
      gradeCounter: null,
      lockRating: false,
    };
  },
  created() {
    this.paramsId = this.$route.params.id;
    this.fetchFollows({ take: this.take, skip: this.skip });
  },
  mounted() {
    this.getRecommendationTeacher();
    this.getAverageScoreStars();
    this.getRelationCourse();
    window.addEventListener("resize", this.reportWindowSize);
    this.reportWindowSize();
    this.userRole = this.$store.state.User.user.role.name;
    if (this.$route.params.id) {
      this.getProfileInformation(this.$route.params.id);
      this.getRecommendation(this.$route.params.id);
      this.getCertificates(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions({
      fetchFollows: "fetchFollows",
    }),
    async getRelationCourse() {
      const response = await coursesService.relationMatriculeStudentWhitTeacher(
        this.paramsId
      );
      this.relationCourse = response;
    },
    openCvUrl(cvUrl) {
      if (cvUrl.length) {
        window.open(cvUrl, "_blank");
      }
    },
    async followUser() {
      try {
        const { result } = await followService.followUser(
          this.profileUserFollow.id
        );
        if (result === "Has empezado a seguir un nuevo usuario") {
          this.$buefy.toast.open({
            duration: 5000,
            position: "is-bottom",
            message: result,
            type: "is-success",
          });
        }
      } catch (error) {
        console.error("Error on followUser", error);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Se presento un error",
          type: "is-danger",
        });
      }
      this.fetchFollows({ take: this.take, skip: this.skip });
    },
    async unfollowUser() {
      try {
        const { result } = await followService.unFollowUser(
          this.profileUserFollow.id
        );
        if (result === "Has dejado de seguir a un usuario") {
          this.$buefy.toast.open({
            duration: 5000,
            position: "is-bottom",
            message: result,
            type: "is-success",
          });
        }
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "No existe la relación que quieres eliminar",
          type: "is-danger",
        });
        console.error("Error on unfollowUser", error);
      }
      this.fetchFollows({ take: this.take, skip: this.skip });
    },
    async score() {
      try {
        const data = {
          post: this.$route.params.id,
          typePost: "teacher",
          starsNumber: this.ranking,
          comment: "comment",
        };
        await profileService.scoreStars(data);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Se envió calificación exitosamente",
          type: "is-success",
        });
        this.getAverageScoreStars();
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: error.response.data.message,
          type: "is-danger",
        });
        console.error("Error on unfollowUser", error);
      }
      this.fetchFollows({ take: this.take, skip: this.skip });
    },
    reportWindowSize() {
      if (window.innerWidth < 550) {
        this.itemsToShow = Number(1);
      } else if (window.innerWidth < 1224) {
        this.itemsToShow = Number(2);
      } else if (window.innerWidth < 1580 || window.innerWidth > 1580) {
        this.itemsToShow = Number(3);
      }
    },
    async getRecommendation(followId) {
      try {
        this.recommendation =
          await recommendationsService.getRecommendationById(followId);
      } catch (error) {
        console.warn("No recommendation for User");
      }
    },
    async getAverageScoreStars() {
      try {
        this.ranking = (
          await profileService.getAverageScoreStars(this.$route.params.id)
        ).averageStars;
        if (this.ranking) {
          this.lockRating = true;
        }
        this.gradeCounter = (
          await profileService.getAverageScoreStars(this.$route.params.id)
        ).totalQualification;
      } catch (error) {
        console.warn("Error");
      }
    },
    async getCertificates(followId) {
      try {
        this.certificatesData = await certificateService.getCertificateById(
          followId
        );
      } catch (error) {
        console.warn("No certificates for User");
      }
    },
    async getRecommendationTeacher() {
      try {
        this.recommendationCounter = (
          await profileService.getRecommendationTeacher(this.$route.params.id)
        ).countRecommendation;
        console.log("recommendationCounter", this.recommendationCounter);
      } catch (error) {
        console.warn("No certificates for User");
      }
    },
    async setRecommendationTeacher() {
      try {
        await profileService.setRecommendationTeacher(this.$route.params.id);
        await this.getRecommendationTeacher();
      } catch (error) {
        console.warn("No certificates for User");
      }
    },
    async getProfileInformation(followId) {
      try {
        this.profileUserFollow = await userService.getOtherUser(followId);
        this.FollowRole = this.profileUserFollow.role.name;
      } catch (error) {
        console.error("Error on getProfileInformation", error);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: error,
          type: "is-danger",
        });
      }
    },
  },
  watch: {
    $route() {
      this.paramsId = this.$route.params.id;
      this.getProfileInformation(this.paramsId);
      this.getRecommendation(this.paramsId);
      this.getCertificates(this.paramsId);
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      follow: "getFollows",
    }),
    isFollowUsers() {
      return !this.follow || !this.profileUserFollow
        ? false
        : this.follow.some((follow) => follow.id === this.profileUserFollow.id);
    },
  },
};
</script>
<style src="./ProfileUsers.scss" lang="scss" scoped></style>
